// Keyframes CSS
@include keyframes(bounceAlpha) {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }

  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }

  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@include keyframes(shiftUp) {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0px);
  }
}

@include keyframes(fadeInUpSm) {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(animStar) {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

@include keyframes(arrowFade) {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.slideOutTop {
  -webkit-animation-name: slideOutTop;
  animation-name: slideOutTop;
}

@include keyframes(slideOutTop) {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-90%);
  }
}

@include keyframes(blockShow) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(bkgSlide) {
  from {
    background-position: 50% 0;
  }

  to {
    background-position: 50% -1080px;
  }
}

@include keyframes(sprite) {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 100%;
  }
}

@include keyframes(spin) {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

@include keyframes(pulse) {
  to {
    opacity: 1;
  }
}

@include keyframes(move) {
  25% {
    opacity: 1;
  }

  33% {
    opacity: 1;
    transform: translateY(25px);
  }

  67% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(65px);
  }
}

@include keyframes(mtop) {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 13px;
    transform: rotate(0);
  }

  100% {
    top: 13px;
    transform: rotate(45deg);
  }
}

@include keyframes(mtop-2) {
  0% {
    top: 13px;
    transform: rotate(45deg);
  }

  50% {
    top: 13px;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@include keyframes(mbottom) {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 14px;
    transform: rotate(0);
  }

  100% {
    bottom: 14px;
    transform: rotate(135deg);
  }
}

@include keyframes(mbottom-2) {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }

  50% {
    bottom: 22px;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@include keyframes(mscaled) {
  50% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(0.6);
  }
}

@include keyframes(mscaled-2) {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@include keyframes(pulsate) {
  0% {
    text-shadow: 0 0 3px #c51e1e;
  }

  50% {
    text-shadow: 0 0 15px #c51e1e;
  }

  100% {
    text-shadow: 0 0 3px #c51e1e;
  }
}


@include keyframes (progressChart) {
  0% {
    stroke-dasharray: 0 100;
  }

  100% {
    stroke-dasharray: 100 100;
  }
}

@include keyframes(arrows) {
  0% {
    border-left: 6.6px solid rgba(255, 255, 255, 0);
    border-bottom: 6.6px solid rgba(255, 255, 255, 0);
    transform: translate(0, -13.3px)rotate(-45deg);
  }

  10%,
  90% {
    border-left: 6.6px solid rgba(255, 255, 255, 0);
    border-bottom: 6.6px solid rgba(255, 255, 255, 0);
  }

  50% {
    border-left: 6.6px solid rgba(255, 255, 255, 0.7);
    border-bottom: 6.6px solid rgba(255, 255, 255, 0.7);
    transform: translate(0, 0)rotate(-45deg);
  }

  100% {
    border-left: 6.6px solid rgba(255, 255, 255, 0);
    border-bottom: 6.6px solid rgba(255, 255, 255, 0);
    transform: translate(0, 13.3px)rotate(-45deg);
  }
}

@include keyframes(colorBkgFade) {
  0% {
    background: rgba(0, 0, 0, 0.05);
  }

  50% {
    background: rgba(0, 0, 0, 0.2);
  }

  100% {
    background: rgba(0, 0, 0, 0.05);
  }
}

@include keyframes(dash-animation) {
  to {
    stroke-dashoffset: 0;
    stroke: rgba(76, 76, 76, 0.5);
  }
}

@include keyframes(fill-svg) {
  0% {
    fill: white;
  }

  50% {
    fill: #dff12f;
  }

  100% {
    fill: white;
  }
}