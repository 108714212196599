$widescreen: 2599px;
$laptop: 1280px;
$tablet: 991px;
$mobile: 767px;
$phone: 500px;

#app-home {
    position: relative;

    #hero {
        position: relative;
        height: 100vh;

        @media(max-width: $tablet) {
            height: 90vh;
        }

        @media only screen and (min-device-width: 1024px) and (min-device-height: 1366px) and (orientation: portrait),
        only screen and (min-device-width: 834px) and (orientation: portrait),
        (min-device-width: 768px) and (orientation: portrait) {
            height: 60vh;
        }

        @media (min-width: 501px) and (max-width: $mobile) {
            height: 90vh;
        }

        @media(max-width: $phone) {
            height: 667px;
        }

        .hero-overlay {
            position: absolute;
            top: -3vw;
            right: 0;
            width: 68vw;
            max-width: 1154px;
            min-width: 360px;

            @media(min-width: $widescreen) {
                width: 53vw;
                max-width: 1580px;

                img {
                    width: 100%;
                }
            }

            @media(max-width: $laptop) {
                top: 0;
                width: 54vw;
            }

            @media (min-width: 501px) and (max-width: $mobile) {
                width: 74vw;
            }
        }

        .hero-underlay-line {
            position: absolute;
            top: -3vw;
            right: 0;
            width: 68vw;
            max-width: 1154px;
            min-width: 360px;

            @media(min-width: $widescreen) {
                width: 53vw;
                max-width: 1580px;

                svg {
                    width: 100%;
                }
            }

            @media(max-width: $laptop) {
                top: 0;
                width: 54vw;
            }

            @media (min-width: 501px) and (max-width: $mobile) {
                width: 74vw;
            }
        }

        .line-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 30vw;
            max-width: 450px;
        }

        .down-overlay {
            position: absolute;
            top: -3vw;
            right: 0;
            width: 68vw;
            max-width: 1154px;
            min-width: 360px;
            animation: arrowFade 2s infinite linear;

            @media(min-width: $widescreen) {
                width: 53vw;
                max-width: 1580px;

                img {
                    width: 100%;
                }
            }

            @media(max-width: $laptop) {
                top: 0;
                width: 54vw;
            }

            @media (min-width: 501px) and (max-width: $mobile) {
                width: 74vw;
            }
        }

        .decal-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 22vw;
            max-width: 411px;
        }

        .caption {
            position: absolute;
            left: 8vw;
            height: 100%;
            max-width: 750px;
            z-index: 1;

            @media(min-width: $widescreen) {
                max-width: 870px;
                left: 15vw;
            }

            @media(max-width: 1566px) {
                left: 3vw;
            }

            @media(max-width: $tablet) {
                height: 90vh;
            }

            @media only screen and (min-device-width: 1024px) and (min-device-height: 1366px) and (orientation: portrait),
            only screen and (min-device-width: 834px) and (orientation: portrait),
            (min-device-width: 768px) and (orientation: portrait) {
                height: 60vh;
            }

            @media(max-width: $mobile) {
                left: inherit;
                bottom: 10vw;
                right: 0;
                padding: 0 30px;
            }

            @media (min-width: 501px) and (max-width: $mobile) {
                height: 90vh;
            }

            @media(max-width: $phone) {
                height: 667px;
            }

            h1 {
                margin-bottom: 1.7rem;

                @media(min-width: $widescreen) {
                    font-size: 4.4rem;
                }

                @media(max-width: 1566px) {
                    font-size: 2.5rem;
                    margin-bottom: 0.6rem;
                }

                @media(max-width: $tablet) {
                    font-size: 1.8rem;
                    margin-bottom: 0;
                }
            }

            h3 {
                margin-bottom: 2rem;

                @media(min-width: $widescreen) {
                    font-size: 2.4rem;
                }

                @media(max-width: 1566px) {
                    font-size: 1.4rem;
                    margin-bottom: 1.2rem;
                }

                @media(max-width: $tablet) {
                    font-size: 1.1rem;
                    margin-bottom: 0.4rem;
                }
            }

            p {
                max-width: 480px;
                line-height: 1.5;

                @media(min-width: $widescreen) {
                    max-width: 580px;
                }

                @media(max-width: 1566px) {
                    font-size: 1rem;
                    max-width: 360px;
                }

                @media(max-width: $tablet) {
                    font-size: 1rem;
                    max-width: 290px;
                }

                @media(max-width: $mobile) {
                    font-size: 1rem;
                    max-width: inherit;
                }
            }

            .btn {
                margin-top: 3rem;

                @media(max-width: $tablet) {
                    margin-top: 1rem;
                }
            }
        }
    }

    #about {
        position: relative;

        @media(max-width: $mobile) {
            margin-top: 2rem;
        }

        .bkg-logo {
            position: absolute;
            width: 100%;
            padding: 20px;
            opacity: 0.04;

            @media(min-width: $widescreen) {
                img {
                    width: 100%;
                }
            }

            @media(max-width: $mobile) {
                bottom: -7rem;
            }
        }

        .wrapper {
            max-width: 600px;
            padding: 0 30px 3rem;
            margin: auto;
            position: relative;

            @media(min-width: $widescreen) {
                max-width: 700px;
            }

            @media(max-width: $mobile) {
                padding-bottom: 4rem;
            }

            @media(max-width: $tablet) {
                font-size: 1rem;
            }

            h2 {
                position: relative;
                margin-bottom: 1.7rem;

                @media(min-width: $widescreen) {
                    font-size: 3.4rem;
                }

                @media(max-width: 1566px) {
                    font-size: 2.5rem;
                }

                @media(max-width: $tablet) {
                    font-size: 1.8rem;
                }
            }

            p {
                line-height: 1.5;
            }

            .target {
                text-align: end;
                position: relative;
                right: -50px;

                #target-img {
                    animation: mscaled 4s infinite alternate;
                }

                @media(max-width: $tablet) {
                    img {
                        width: 4vw;
                    }

                    right: 0;
                }

                @media(max-width: $mobile) {
                    img {
                        width: 6vw;
                    }
                }
            }
        }
    }

    #contact {
        position: relative;
        background: url('../assets/footer_bkg.jpg') center top no-repeat;
        background-size: cover;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            animation: colorBkgFade 3s infinite alternate;
        }

        ::placeholder {
            color: #fff;
            opacity: 1;
            /* Firefox */
        }

        ::-ms-input-placeholder {
            /* Edge 12 -18 */
            color: #fff;
        }

        #contact-form {
            position: relative;
        }

        #contact-header {
            @media(max-width: $mobile) {
                margin-top: 4rem;
                text-align: center;

                h2 {
                    display: inline-block;
                }
            }
        }

        .form-control {
            background-color: rgba(5, 15, 13, 0.57);
            border-radius: 0;
            outline: none;
            box-shadow: none;
            border: 0;
            color: #fff;
            padding: 0.8rem 1rem 0.6rem;
            margin-bottom: 15px;
            font-size: 1rem;

            &:focus {
                outline: none;
                box-shadow: none;
                border-color: transparent;
            }
        }

        textarea {
            min-height: 135px;
        }

        .container-xl {
            max-width: 1640px;
            margin: auto;

            #contact-form form {
                padding: 9rem 0 7rem;

                @media(max-width: $mobile) {
                    padding: 3rem 0 4rem;
                }
            }
        }

        #newsletter {
            max-width: 420px;
            color: #fff;
            position: relative;

            @media(max-width: $tablet) {
                font-size: 1rem;
            }

            form {
                margin-bottom: 5rem;
            }
        }

        .social-block {
            color: #fff;
            position: relative;

            a {
                background-color: #dff12f;
                margin-right: 20px;
                padding: 5px 5px 3px 5px;
                border-radius: 6px;

                @media(max-width: $mobile) {
                    font-size: 0.8rem;
                    margin-right: 10px;
                }

                i {
                    color: #000;
                    transition: color 0.3s ease-in;
                }

                &:hover {
                    background-color: #000;

                    i {
                        color: #dff12f;
                    }
                }
            }
        }
    }

    .alert {
        font-size: 1rem;
        border-radius: 0;
    }

    .btn-submit {
        padding: 0.3rem 0;
        position: relative;
        border: 0 solid transparent !important;

        @media(max-width: $mobile) {
            border-radius: 0;
            border: 1px solid #dff12f !important;
            padding: 0.6rem 0.8rem;
            background-color: #dff12f;
            color: #000 !important;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 1px;
            right: 0;
            left: 0;
            margin: auto;
            height: 2px;
            width: 0;
            background-color: #dff12f;
            transition: width 0.2s ease-in;
        }

        &:hover {
            color: #dff12f;

            @media(max-width: $mobile) {
                color: #000;
            }

            &:before {
                width: 100%;

                @media(max-width: $mobile) {
                    width: 0;
                }
            }
        }

    }

    #app-footer {
        .footer-links {
            a {
                color: #fff;

                &:hover {
                    color: #dff12f;
                }
            }
        }

        .footer-legal {
            color: #fff;
        }
    }
}