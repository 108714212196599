$widescreen: 2599px;
$desktop: 1799px;
$laptop: 1280px;
$tablet: 991px;
$mobile: 767px;
$phone: 500px;

#app-team {
    position: relative;

    #team-members {
        margin: auto;
        padding: 10rem 8rem;
        max-width: 1920px;

        @media(max-width: $desktop) {
            padding: 8rem 4rem;
            max-width: 1499px;
        }

        @media(max-width: $laptop) {
            padding: 5rem 2rem;
            max-width: 1099px;
        }

        @media(max-width: $mobile) {
            max-width: 449px;
        }

        .col {
            .card {
                overflow: hidden;
                position: relative;
                border: 0;

                .card-image {
                    background-color: #c2c2aa;

                    img {
                        transition: all 0.8s ease-in-out;
                        transform: scale(1.06);
                        filter: grayscale(100%);
                    }

                    &.holder {
                        padding: 3.6rem;

                        img {
                            opacity: 0.2;
                            filter: none;
                        }

                    }
                }

                .card-title {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    z-index: 1;
                    padding: 0.4rem 0.3rem;
                    background-color: #dff12f;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: 1;
                    font-size: 17px;
                    transition: all 0.3s ease-in-out;

                    @media(max-width: $desktop) {
                        font-size: 15px;
                    }

                    @media(max-width: $laptop) {
                        font-size: 14px;
                    }

                    @media(max-width: $mobile) {
                        font-size: 17px;
                        position: relative;
                        padding: 1.5rem 0.6rem 0.6rem;
                    }
                }

                .card-overlay {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0.4;
                    transition: all 0.8s ease-in-out;
                    padding: 2rem 1.5rem;

                    @media(max-width: $laptop) {
                        font-size: 15px;
                    }

                    @media(max-width: $mobile) {
                        opacity: 1;
                        top: 0;
                        padding: 0.2rem 1.5rem 1.5rem;
                        position: relative;
                        text-align: center;
                        background-color: rgba(222, 241, 47, 1);
                    }

                    .bio {
                        position: relative;
                        z-index: 1;
                        opacity: 0;
                        transition: all 0.3s 0.4s ease-in-out;

                        @media(max-width: $mobile) {
                            opacity: 1;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transition: all 1s ease-in-out;
                        background-color: rgba(222, 241, 47, 0.9);
                        transform: skewY(-8deg);

                        @media(max-width: $mobile) {
                            content: none;
                        }
                    }
                }

                &:hover {
                    .card-image img {
                        transform: scale(1);
                    }

                    .card-title {
                        opacity: 0;

                        @media(max-width: $mobile) {
                            opacity: 1;
                        }
                    }

                    .card-overlay {
                        top: 20%;
                        opacity: 0.85;

                        @media(max-width: $mobile) {
                            opacity: 1;
                            top: 0;
                        }

                        &:before {
                            transform: skewY(0deg);
                        }

                        .bio {
                            opacity: 1;
                        }
                    }
                }
            }

            &:nth-child(odd) {
                .card-title {
                    background-color: rgb(186, 186, 160);
                }

                .card-overlay {
                    @media(max-width: $mobile) {
                        background-color: rgba(186, 186, 160, 0.8);
                    }

                    &:before {
                        background-color: rgba(186, 186, 160, 0.8);
                    }
                }
            }

        }
    }

    .slash-lines {
        position: relative;
        text-align: center;
        padding: 0 2rem;
        margin: 0 auto 6rem;
    }

    #key-partners {
        position: relative;

        h3 {
            text-align: center;
            text-transform: uppercase;
            background-color: #c6c6b9;
            padding: 0 1rem;
            position: absolute;
            z-index: 2;
            margin: auto;
            width: 18rem;
            top: -15px;
            left: 0;
            right: 0;

            @media(max-width: $mobile) {
                width: 14rem;
            }
        }

        #partner-logos {
            border: 2px solid #42423e;
            margin-bottom: 2rem;
            position: relative;
            z-index: 1;

            .grid {
                text-align: center;
                width: 25%;
                border-right: 2px solid #000;
                padding: 0rem 4rem;
                margin: 3rem auto;

                &:last-child {
                    border: 0;
                }

                .card-title {
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 1.5rem auto 0;

                    span {
                        position: relative;
                        padding: 0 1rem;
                        background-color: #c6c6b9;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        right: 0;
                        top: 50%;
                        height: 2px;
                        width: 100%;
                        background-color: #dff12f;
                    }
                }

                @media(max-width: $laptop) {
                    padding: 1rem 2rem;
                }

                @media(max-width: $mobile) {
                    padding: 0 4rem;
                    width: 100%;
                    border-right: 0 solid #000;
                    margin: 0 auto 2rem;

                    &:first-child {
                        margin-top: 3rem;
                    }
                }
            }
        }

        #partner-titles {
            position: relative;
            z-index: 0;

            .col {
                .card {
                    position: relative;
                    border: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        height: 2px;
                        width: 100%;
                        background-color: #dff12f;
                    }

                    .card-title {
                        position: relative;
                        padding: 0 1rem;
                        text-align: center;
                        text-transform: uppercase;
                        background-color: #c6c6b9;
                        margin: 0 auto;
                    }
                }

                &:first-child {
                    .card {
                        &:before {
                            width: 50%;
                        }
                    }
                }

                &:last-child {
                    .card {
                        &:before {
                            right: inherit;
                            left: 0;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}