@charset "UTF-8";
@import url('https://use.typekit.net/wga0wnq.css');
@import "vars";
@import '~bootstrap';
@import '~bootstrap-icons';
@import "~glightbox/dist/css/glightbox.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "animate";
@import "mixins";
@import "keyframes";
@import "main";
@import "header";
@import "footer";