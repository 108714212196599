$widescreen: 2599px;
$desktop: 1799px;
$laptop: 1280px;
$tablet: 991px;
$mobile: 767px;
$phone: 500px;
$red: #f13433;

#app-defect {
    position: relative;
    color: #fff;
    background-color: #000;
    font-size: 1.1rem;

    .red {
        color: $red !important;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-weight: 500;
        font-style: normal;
        font-family: cake-mono, sans-serif;
    }

    #app-nav {
        .navbar-nav {
            a {

                &:before,
                &:after {
                    background-color: $red !important;
                }

                &:hover {
                    color: $red;
                }
            }
        }

        .social-block {
            a {
                &:hover {
                    background-color: $red;
                }
            }
        }
    }

    #hero {
        position: relative;
        text-align: center;
        height: 100vh;
        background: url('../assets/defect/defect_hero_bkg.jpg') no-repeat center center;
        background-size: cover;

        @media(max-width: $mobile) {
            height: 85vh;
            background-position: 20% 0;
        }

        .video-wrapper {
            height: 100%;
            left: 0;
            overflow: hidden;
            pointer-events: none;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 0;

            @media(max-width: $mobile) {
                display: none !important;
            }

            video {
                height: 56.25vw;
                left: 50%;
                min-height: 100vh;
                min-width: 177.77vh;
                position: absolute;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 100vw;
            }
        }

        .decal-overlay {
            position: absolute;
            bottom: -9vw;
            left: -7vw;
            width: 28vw;
            max-width: 500px;

            @media(min-width: 1820px) {
                left: -8rem;
                bottom: -10rem;
            }
        }

        .decal-overlay-right {
            position: absolute;
            bottom: -8vh;
            right: 0;
            width: 6vw;
            max-width: 50px;
        }

        .caption-wrapper {
            position: relative;
            width: 100%;
            max-width: 1075px;
            margin: auto;
        }

        .content-wrapper {
            width: 100%;
            max-width: 750px;
            margin: 2rem auto 0;

            h1 {
                margin-bottom: 2rem;
                line-height: 1;
            }

            .btn-theme {
                background-color: $red;
                border: 1px solid $red;
                color: white;

                &:hover {
                    border: 1px solid $red;
                    background-color: #000;
                }
            }
        }
    }

    #trailer {
        padding: 10vw 0 2rem;
        position: relative;
        background-color: #000;

        @media(max-width: 1599px) {
            padding-top: 9.5rem;
        }

        @media(max-width: $tablet) {
            padding-top: 8rem;
        }

        @media(max-width: $mobile) {
            padding-top: 16vw;
        }

        .headline-block {
            position: absolute;
            top: 9.4%;
            left: 16vw;
            width: 32vw;
            max-width: 328px;

            @media(min-width: 1820px) {
                left: 18rem;
                top: 7rem;
            }

            h2 {
                position: absolute;
                top: 0;
                left: 24%;

                @media(max-width: $tablet) {
                    font-size: 4vw;
                }

                @media(max-width: $mobile) {
                    font-size: 5vw;
                    top: -8px;
                }
            }
        }

        #trailer-zoom {
            position: relative;
            border: 2px $red solid;
            overflow: hidden;

            .placeholder-img {
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: #000;
                    opacity: 0;
                    transition: opacity 0.6s ease;
                }
            }

            .play-btn-wrapper {
                position: absolute;
                opacity: 0.4;
                z-index: 2;
                right: 0;
                left: 0;
                margin: auto;
                top: 50%;
                transform: translateY(-50%);
                max-width: 190px;
                width: 10vw;
                cursor: pointer;
                transition: opacity 0.4s ease;
            }

            &:hover {
                .placeholder-img {
                    &:before {
                        opacity: 0.4;
                    }
                }

                .play-btn-wrapper {
                    opacity: 1;
                }
            }
        }
    }

    #about {
        text-align: center;
        height: 90vh;
        min-height: 880px;
        overflow: hidden;
        position: relative;
        background-color: #000;

        @media(max-width: $mobile) {
            height: 40vh;
            min-height: 720px;
        }

        .title-header {
            font-size: 4rem;
            line-height: 1;

            @media(max-width: $mobile) {
                font-size: 3rem;
            }
        }

        #rxt-bar {
            position: absolute;
            right: -3vw;
            width: clamp(260px, 30vw, 777px);
        }

        #oecp-bar {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            width: clamp(100px, 17vw, 234px);
        }

        .overlay {
            margin: auto;
            position: relative;
            z-index: 1;

            .block-container {
                margin: auto;
                max-width: 870px;
            }

            .police-logo {
                margin: auto;
                text-align: center;
                max-width: 344px;
                padding-bottom: 15vh;

                @media(max-width: $mobile) {
                    max-width: 200px;
                }
            }
        }
    }

    #community {
        background-color: $red;
        padding: 2rem 20px;
        position: relative;

        h2 {
            margin: auto;
            text-align: center;

            @media(max-width: 1199px) {
                .linebar {
                    display: none;
                }
            }

            svg {
                width: 294px;
                margin-right: 1rem;
                margin-left: 1rem;
                margin-top: -0.4rem;

                @media(max-width: $mobile) {
                    margin-top: 10px;
                }
            }

            .linebar {
                position: relative;
                top: -2rem;
                width: 180px;
                margin-right: 1rem;

                &:last-of-type {
                    transform: scaleX(-1);
                    margin-left: 1rem;
                    margin-right: 0;
                }
            }
        }

        .social-block {
            margin: 1.3rem auto 0;
            text-align: center;

            a {
                background-color: #fff;
                margin-right: 20px;
                padding: 5px 5px 3px 5px;
                border-radius: 6px;

                @media(max-width: $mobile) {
                    font-size: 0.8rem;
                    margin-right: 10px;
                }

                i {
                    color: $red;
                    transition: color 0.3s ease-in;
                }

                &:hover {
                    background-color: #000;

                    i {
                        color: #fff;
                    }
                }
            }
        }
    }

    #gallery {
        position: relative;
        padding: 4rem 0;
        text-align: center;
        background-color: #000;

        .heading {
            position: relative;
            margin-top: 3rem;

            .arrows {
                position: absolute;
                top: -3px;
                width: 18px;

                @media(max-width: $mobile) {
                    width: 10px;
                }

                &:first-of-type {
                    left: 0;
                    transform: scaleX(-1);
                }

                &:last-of-type {
                    right: 0;
                }
            }
        }

        .content {
            max-width: 870px;
            margin: auto;
            padding: 1rem 0 4rem;
        }

        .swiper-slide {
            overflow: hidden;
            border: 2px solid $red;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                transition: background-color 0.4s ease;
            }

            img {
                transition: transform 2s linear;
                object-fit: cover;
            }

            .title {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                padding: 0.4rem 0.6rem 0.4rem;
                border-top: 2px solid $red;
                text-decoration: none;
                color: white;
                background-color: rgba(0, 0, 0, 0.35);
                font-size: 16px;

                @media(max-width: $mobile) {
                    font-size: 12px;
                }
            }

            &.swiper-slide-active,
            &:hover {
                &:before {
                    background-color: rgba(0, 0, 0, 0);
                }

                img {
                    transform: scale(1.1);
                }
            }
        }

        .swiper-arrow {
            position: absolute;
            top: 50%;
            width: clamp(14px, 1vw, 24px);
            height: clamp(14px, 1vw, 24px);
            z-index: 10;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &.swiper-next-arrow {
                right: 0%;
                transform: translateY(-50%) scale(-1);
            }

            &.swiper-prev-arrow {
                left: 0%;
                transform: translateY(-50%);
            }
        }

    }

    #signup {
        text-align: center;
        padding: 4rem 0 3rem;
        background-color: #000;
        position: relative;
        overflow: hidden;
        font-size: 1rem;

        @media(max-width: $mobile) {
            padding: 4rem 30px 2rem;
        }

        #newsletter {
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            margin-top: -2rem;

            @media(max-width: 1199px) {
                display: block;
                max-width: 470px;
                margin: -3rem auto 0;
            }

            h2 {
                margin: 0 2rem 0 0;
                padding: 0;
                color: $red !important;

                @media(max-width: 1199px) {
                    margin-bottom: 1rem;
                }
            }

            .input-group {
                margin: 0 !important;
            }

            .form-control {
                background-color: #172020;
                border-radius: 0;
                border: 2px solid $red;

                &::placeholder {
                    color: #a7a7a7;
                }
            }

            .btn {
                background-color: $red;
            }
        }

        #left-arrow-signup {
            position: absolute;
            top: 0;
            left: 0;

            @media(max-width: 1660px) {
                left: -10vw;
            }

            @media(max-width: 1376px) {
                left: -20vw;
            }

            @media(max-width: $tablet) {
                left: -37vw;
            }
        }

        #right-arrow-signup {
            position: absolute;
            top: 0;
            right: 0;
            transform: scaleX(-1);

            @media(max-width: 1660px) {
                right: -10vw;
            }

            @media(max-width: 1376px) {
                right: -20vw;
            }

            @media(max-width: $tablet) {
                right: -37vw;
            }
        }

        #left-arrow-signup,
        #right-arrow-signup {
            @media(max-width: $mobile) {
                display: none !important;
            }


        }
    }

    #app-footer {
        position: relative;
        background-color: #000;
        margin-top: 0;
        padding-top: 10rem;

        .link {
            color: white;

            &:hover {
                color: $red;
            }
        }

        .social-block a {
            background-color: $red;

            &:hover i {
                color: white;
            }
        }

        .footer-legal {
            color: white;
        }
    }
}