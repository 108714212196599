#app-footer {
    position: relative;
    text-align: center;
    margin-top: 10rem;
    padding-bottom: 1.4rem;

    .navbar-brand {
        width: 102px;
        height: 76px;
        margin: auto;
    }

    .offset-esrb {
        width: 70px;
    }

    .social-block {
        padding: 2.3rem 0;

        a {
            background-color: $theme;
            margin-right: 20px;
            padding: 5px 5px 3px 5px;
            border-radius: 6px;

            @media(max-width: $mobile) {
                font-size: 0.8rem;
                margin-right: 10px;
            }

            i {
                color: $black;
                transition: color 0.3s ease-in;
            }

            &:hover {
                background-color: $black;

                i {
                    color: $theme;
                }
            }
        }
    }

    .footer-links {
        a {
            color: $black;
            text-transform: uppercase;
            text-decoration: none;
            margin: 0 1rem;
            font-size: 1rem;

            @media(max-width: $mobile) {
                margin-bottom: 0.5rem;
            }

            &:hover {
                color: $theme;
            }
        }
    }

    .footer-legal {
        margin-top: 1rem;
        color: $black;
        font-size: 12px;
    }
}