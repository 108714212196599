$use-all: true;
/**** VARIABLES ****/
$darken-overlay: (
  10: 0.1,
  15: 0.15,
  20: 0.2,
  25: 0.25,
  30: 0.3,
  35: 0.35,
  40: 0.4,
  45: 0.45,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8
);
$delays: (
  1: 0.3,
  2: 0.6,
  3: 0.9,
  4: 1.2,
  5: 1.5,
  6: 1.6,
  7: 1.9,
  8: 2.1,
  9: 2.3,
  10: 2.5,
  11: 2.7,
  12: 2.9,
  13: 3.1,
  14: 3.3
);
$maxW: (
  500,
  600,
  700,
  800,
  900,
  1000,
  1200,
  1400
);
/***** BREAKPOINTS OVERRIDES *****/
$widescreen: 2599px;
$fullsize: 1799px;
$desktop: 1399px;
$laptop: 1280px;
$tablet: 991px;
$mobile: 767px;
$phone: 500px;
$iphone: 375px;
$iphone5: 374px;
/***** COLORS *****/
$body-bg: #c6c6b9;
$body-color: #000;
$black: #000;
$dark: #020202;
$white: #fff;
$dark-red: #500000;
$dark-red2: #690000;
$red: #c10000;
$theme: #dff12f;
$gray: #252525;
$colors: (
  "white": $white,
  "red": $red,
  "dark-red": $dark-red,
  "dark-red2": $dark-red2,
  "black": $black,
  "dark": $dark,
  "theme": $theme,
  "gray": $gray
);
/***** BOOTSTRAP OVERRIDES *****/
$bootstrap-sass-asset-helper: true !important;
$border-radius: 10px;
$btn-close-width: 1.5em;
$spacer: 1rem;
$card-group-margin: 1rem;
$font-size: 1rem;
$font-family-base: 'citizen', cursive;
$headings-font-family: 'citizen', cursive;
$font-size-base: 1.2rem; // Assumes the browser default, typically `16px`
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-black: 600;
$font-weight-base: 500;
$line-height-base: 1.2;
$nav-link-height: 1rem !default;
$navbar-brand-padding-y: 1rem !default;
$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 1.8 !default;
$h4-font-size: $font-size-base * 1.4 !default;
$h5-font-size: $font-size-base * 1.2 !default;
$h6-font-size: $font-size-base !default;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 2rem;
$headings-color: null;
$display-font-sizes: (
  1: $font-size-base * 3,
  2: $font-size-base * 2.5,
  3: $font-size-base * 1.8,
  4: $font-size-base * 1.4,
  5: $font-size-base * 1.2,
  6: $font-size-base) !default;
$display-font-weight: $font-weight-bold !default;
$display-line-height: 1;
$grid-gutter-width: 40px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1248px,
  sl: 1800px) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px,
  sl: 1760px) !default;
$card-border-radius: 0;
$card-border-color: transparent;
$modal-md: 800px;
$modal-content-border-radius: 0;
$modal-backdrop-opacity: 0.8;
$modal-content-border-width: 0;
/***** CUSTOM *****/
$headerHeight: 500px;
$minHeaderHeight: 480px;
$assets-path: "../assets";