html {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
}

a {
    transition: all 0.3s ease-in;
}

.offset-top {
    padding-top: 10rem;

    @media(max-width: $laptop) {
        padding-top: 8rem;
    }

    @media(max-width: $tablet) {
        padding-top: 6rem;
    }
}

.cake {
    font-family: cake-mono, sans-serif;
    font-style: normal;
    font-weight: 300;
}

.bkg-zoom {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    z-index: 0;
}

.draw-line {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: -7rem;
        top: 50%;
        width: 5rem;
        height: 4px;
        background-color: $theme;
        @include transform(-50%);
    }
}


.inview {
    opacity: 0;

    &.animated {
        opacity: 0;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;

        &.delay-1 {
            -webkit-animation-dely: 0.4s;
            animation-delay: 0.4s;
        }

        &.delay-2 {
            -webkit-animation-dely: 0.6s;
            animation-delay: 0.6s;
        }

        &.delay-3 {
            -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }

        &.delay-4 {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }

        &.delay-5 {
            -webkit-animation-delay: 1s;
            animation-delay: 1.2s;
        }
    }

    &.fadeInUp {
        animation-name: fadeInUp;
    }
}

#app-loader {
    height: 100vh;
    width: 100%;
    background: $body-bg;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .inner-box {
        margin: 0 auto;
        padding: 3rem;
        text-align: center;
        max-width: 240px;
        height: 100vh;
        display: flex;
        align-items: center;

        .path-svg {
            animation: fill-svg 2s infinite forwards;
        }
    }
}

main {
    position: relative;

    @each $name,
    $color in $colors {
        .#{$name} {
            color: $color !important;
        }
    }

    section {
        position: relative;
        width: 100%;
    }

    .slim {
        font-family: "countach", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .bold {
        font-weight: $font-weight-bold;
    }

    .icon {
        transition: fill 0.4s ease-in-out;

        &:hover {
            fill: $red;
        }
    }

    .btn-theme {
        background-color: $theme;
        border-radius: 0;
        padding: 0.4rem 1.4rem 0.5rem;
        transition: all 0.4s ease-in-out;
        text-transform: uppercase;
        color: $black;

        @media(max-width: $tablet) {
            font-size: 1rem;
        }

        &:hover,
        &:active {
            color: $black;
            background-color: $theme;
        }

        &.btn-black {
            background-color: $black;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
            color: $theme;

            &:hover,
            &:active {
                color: $black;
                background-color: $theme;
            }
        }
    }
}

#app-pages {
    font-size: 1.2rem;

    @media(min-width: $widescreen) {
        font-size: 1.3rem;
    }
}

#app-common-pages {
    .content-block {
        background-color: rgba(189, 189, 189, 0.85);
        padding: 4rem;

        @media(max-width: $laptop) {
            padding: 3rem;
        }

        @media(max-width: $mobile) {
            padding: 2rem;
        }

        h2,
        h3,
        h4 {
            font-family: cake-mono, sans-serif;
            font-style: normal;
            font-weight: 300;
        }
    }
}

.decal-overlay-top {
    position: absolute;
    top: -12rem;
    right: 0;
    width: 16vw;
    max-width: 311px;
    @include transform(scaleX(-1));

    @media(max-width: $laptop) {
        top: -5rem;
    }

    @media(max-width: $mobile) {
        top: 0;
        //width: 12vw;
    }
}

.decal-overlay-bottom {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 4vw;
    max-width: 40px;
}

.decal-overlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -60px;
    width: 18vw;
    max-width: 411px;
}

.stroke-lines {
    &.fadeIn {
        .clip {
            clip-path: url(#clip-path)
        }

        rect {
            fill: none;
        }

        polygon {
            fill: none;
            stroke: rgba(34, 34, 34, 0.36);
            stroke-width: 1.4px;
            stroke-dasharray: 5000;
            stroke-dashoffset: 5000;
            animation: dash-animation 3.5s linear forwards;
        }

        line {
            fill: none;
            stroke: rgba(34, 34, 34, 0.36);
            stroke-width: 1.4px;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: dash-animation 4s linear forwards;
        }

        .st0 {
            clip-path: url(#SVGID_2_);
        }

    }
}

.draw-lines {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .hero-underlay-line {
        position: absolute;
        top: -3vw;
        right: 0;
        width: 68vw;
        max-width: 1154px;
        min-width: 360px;

        @media(min-width: $widescreen) {
            width: 53vw;
            max-width: 1580px;

            svg {
                width: 100%;
            }
        }

        @media(max-width: $laptop) {
            top: 0;
            width: 54vw;
        }

        @media (min-width: 501px) and (max-width: $mobile) {
            width: 74vw;
        }
    }

    .line-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 30vw;
        max-width: 450px;
    }
}